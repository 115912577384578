import { FC } from 'react'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { useGoogleAnalytics, useHotjar } from 'utils/hooks'
import { config } from 'config/config'
import '@fontsource/inter/400.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import 'styles/globals.css'

const MyApp: FC<AppProps> = ({ Component, pageProps: { session, ...pageProps } }) => {
  useGoogleAnalytics()
  useHotjar()

  return (
    <>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${config.ga.trackingId}`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.ga.trackingId}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <SessionProvider session={session}>
        <Component {...pageProps} />
      </SessionProvider>
    </>
  )
}

export default appWithTranslation(MyApp)

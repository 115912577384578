import { i18n } from 'next-i18next.config'

export const config: Config = {
  host: process.env.HOST || '',
  isProd: process.env.NODE_ENV === 'production',
  defaultLocale: i18n.defaultLocale,
  next: {
    pageRevalidateDelay: 30,
  },
  passphrase: 'collab',
  sanity: {
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || '',
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
    token: process.env.SANITY_API_TOKEN || '',
  },
  nextAuth: {
    secret: process.env.NEXTAUTH_SECRET || '',
    google: {
      clientId: process.env.GOOGLE_CLIENT_ID || '',
      clientSecret: process.env.GOOGLE_CLIENT_SECRET || '',
    },
    discord: {
      clientId: process.env.DISCORD_CLIENT_ID || '',
      clientSecret: process.env.DISCORD_CLIENT_SECRET || '',
    },
  },
  mongodb: {
    url: process.env.MONGODB_URL || '',
  },
  hotjar: {
    id: Number(process.env.NEXT_PUBLIC_HOTJAR_ID || ''),
    snippetVersion: Number(process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION || ''),
  },
  ga: {
    trackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID || '',
  },
}

interface Config {
  host: string
  isProd: boolean
  defaultLocale: string
  next: {
    pageRevalidateDelay: number
  }
  passphrase: string
  sanity: {
    projectId: string
    dataset: string
    token: string
  }
  nextAuth: {
    secret: string
    google: {
      clientId: string
      clientSecret: string
    }
    discord: {
      clientId: string
      clientSecret: string
    }
  }
  mongodb: {
    url: string
  }
  hotjar: {
    id: number
    snippetVersion: number
  }
  ga: {
    trackingId: string
  }
}

import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { pageView } from '../analytics-utils'

export const useGoogleAnalytics = (): void => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: URL) => pageView(url)

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events])
}

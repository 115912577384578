import { createClient, createPreviewSubscriptionHook } from 'next-sanity'
import type { SanityImageSource } from '@sanity/image-url/lib/types/types'
import type { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder'
import createImageUrlBuilder from '@sanity/image-url'
import { config } from 'config/config'

export const clientConfig = {
  projectId: config.sanity.projectId,
  dataset: config.sanity.dataset,
  apiVersion: '2022-01-09', // Learn more: https://www.sanity.io/docs/api-versioning
  /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   **/
  useCdn: true,
}

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const urlFor = (source: SanityImageSource): ImageUrlBuilder =>
  createImageUrlBuilder(clientConfig).image(source) as ImageUrlBuilder

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(clientConfig)

const sanityClient = createClient(clientConfig)

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...clientConfig,
  useCdn: false,
  token: config.sanity.token,
})

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview: boolean) =>
  usePreview ? previewClient : sanityClient

const path = require('path')

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'pt', 'fr'],
  },
  reloadOnPrerender: process.env.NODE_ENV !== 'production',
  localePath:
    typeof window === 'undefined' ? path.resolve('./public/locales') : '/locales',
}

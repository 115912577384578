import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { config } from 'config/config'

export const useHotjar = (): void => {
  useEffect(() => {
    if (config.isProd) {
      hotjar.initialize(config.hotjar.id, config.hotjar.snippetVersion)
    }
  }, [])
}
